







































import { Vue, Component, Prop } from "vue-property-decorator";
import { authModule } from "@/store/modules/auth";

@Component
export default class Login extends Vue {
  private username: string = null;
  private password: string = null;
  private isAuthenticated: boolean = false;

  public async login() {
    if (this.username && this.password) {
      if (
        await authModule.login({
          username: this.username,
          password: this.password
        })
      ) {
        this.$router.push("/organisations");
      }
    }
  }

  public mounted() {
    this.isAuthenticated = authModule.isAuthenticated;
  }

  public async logout() {
    authModule.logout();
    this.isAuthenticated = false;
  }
}
